import { g as getElement } from "./index-bc9b1baf.js";
import "./MutationObserverSafe-8cdfc100.js";
import { C as CTX_KEY, j as SUBSCRIPTIONS_KEY, m as isElementReady } from "./element-d66cb8c2.js";
import { a as SubscriptionList, B as BehaviorSubject } from "./subscription-list.class-20a90866.js";
var GET_CONTEXT_RETRY_COUNT = 40;
var GET_CONTEXT_RETRY_INTERVAL = 50;
var UNSUBSCRIBE_TIMEOUT = 2e3;
var isElementMatched = function (t, n, e) {
  var i;
  if (!n) {
    return false;
  }
  if (e) {
    if (typeof e === "function") {
      return e(n, t);
    } else {
      return ((i = n.tagName) === null || i === void 0 ? void 0 : i.toLowerCase()) === e.toLowerCase();
    }
  } else {
    return true;
  }
};
var findElementFromTarget = function (t, n) {
  var e = t;
  while (e && !isElementMatched(t, e, n)) {
    e = e === null || e === void 0 ? void 0 : e.parentElement;
  }
  return e;
};
var getContext = function (t, n, e) {
  if (e === void 0) {
    e = false;
  }
  var i = t === null || t === void 0 ? void 0 : t[CTX_KEY];
  if (i && i instanceof Map && i.has(n)) {
    return {
      context: i.get(n),
      element: t
    };
  }
  if (!e) {
    var o = t === null || t === void 0 ? void 0 : t.parentElement;
    if (o) {
      return getContext(o, n);
    }
  }
  return undefined;
};
var addContext = function (t, n, e) {
  if (t) {
    !(t[CTX_KEY] && t[CTX_KEY] instanceof Map) && (t[CTX_KEY] = new Map());
    t[CTX_KEY].set(n, new BehaviorSubject(e));
    return t[CTX_KEY].get(n);
  }
};
var onContextChangeMethod = function (t, n, e, i) {
  var o;
  var r = getElement(t);
  var a = t[n];
  !(r[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (r[SUBSCRIPTIONS_KEY] = new SubscriptionList());
  var u = r[SUBSCRIPTIONS_KEY];
  if (i === null || i === void 0 ? void 0 : i.target) {
    var v = findElementFromTarget(r, i.target);
    var l = (o = getContext(v, e, true)) === null || o === void 0 ? void 0 : o.context;
    l && (u[e] = l.subscribe(function (n, e) {
      return a.call(t, n, e);
    }));
  } else {
    isElementReady(function () {
      return getContext(r, e);
    }, GET_CONTEXT_RETRY_COUNT, GET_CONTEXT_RETRY_INTERVAL).then(function () {
      var n;
      var i = (n = getContext(r, e)) === null || n === void 0 ? void 0 : n.context;
      i && (u[e] = i.subscribe(function (n, e) {
        return a.call(t, n, e);
      }));
    });
  }
};
var ContextValue = function () {
  function t() {}
  t.prototype.set = function (t, n) {
    if (n === void 0) {
      n = false;
    }
    var e;
    var i = getContext(this._cachedElement || this.hostElement, this.ctxKey);
    this._cachedElement = i.element;
    (e = i === null || i === void 0 ? void 0 : i.context) === null || e === void 0 ? void 0 : e.next(t, n);
  };
  t.prototype.get = function () {
    var t;
    var n = getContext(this._cachedElement || this.hostElement, this.ctxKey);
    this._cachedElement = n.element;
    return (t = n === null || n === void 0 ? void 0 : n.context) === null || t === void 0 ? void 0 : t.getValue();
  };
  return t;
}();
function Context(t) {
  return function (n, e) {
    var i = n.connectedCallback;
    n.connectedCallback = function () {
      var n = getElement(this);
      var o = getContext(n, t);
      !o && addContext(n, t);
      !(this[e] instanceof ContextValue) && (this[e] = new ContextValue());
      this[e].hostElement = n;
      this[e].ctxKey = t;
      return i === null || i === void 0 ? void 0 : i.call(this);
    };
  };
}
function OnContextChange(t, n) {
  return function (e, i) {
    var o = e.connectedCallback,
      r = e.disconnectedCallback,
      a = e.componentWillLoad,
      u = e.componentDidLoad;
    var v = (n || {}).stage,
      l = v === void 0 ? "willLoad" : v;
    var d;
    e.connectedCallback = function () {
      var e;
      if ((n === null || n === void 0 ? void 0 : n.target) && !d) {
        var i = getElement(this);
        var r = findElementFromTarget(i, n.target);
        var a = (e = getContext(r, t, true)) === null || e === void 0 ? void 0 : e.context;
        r && !a && (a = addContext(r, t));
      }
      d && clearTimeout(d);
      return o === null || o === void 0 ? void 0 : o.call(this);
    };
    l === "willLoad" && (e.componentWillLoad = function () {
      onContextChangeMethod(this, i, t, n);
      return a === null || a === void 0 ? void 0 : a.call(this);
    });
    l === "didLoad" && (e.componentDidLoad = function () {
      onContextChangeMethod(this, i, t, n);
      return u === null || u === void 0 ? void 0 : u.call(this);
    });
    e.disconnectedCallback = function () {
      var n = getElement(this);
      d = setTimeout(function () {
        var e = n === null || n === void 0 ? void 0 : n[SUBSCRIPTIONS_KEY];
        e && e instanceof SubscriptionList && e.unsubscribeSafe(t);
      }, UNSUBSCRIBE_TIMEOUT);
      return r === null || r === void 0 ? void 0 : r.call(this);
    };
  };
}
export { ContextValue as C, OnContextChange as O, Context as a };